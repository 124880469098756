import React from "react"
import { graphql } from "gatsby"

import { Layout } from "../components/layout"
import { renderComponent } from "../utils/components"
import { getTags } from "../utils/seo"

const NotFoundPage = ({ data }) => {
  const { contentfulSite } = data

  const hero = {
    id: "hero-404",
    __typename: "ContentfulHeroTituloSimples",
    titulo: "Página nao encontrada"
  }

  const bodyComp = {
    id: "get-started",
    __typename: "ContentfulBlocosBannerTexto",
    titulo:
      "A página que procura não foi encontrada, pode ter sido removida ou mudado de local, ou estar temporariamente indisponível",
    prefixo: "404",
    variante: "light",
    cta1: {
      titulo: "Página inicial",
      paginaInterna: {
        id: contentfulSite.homepage.id,
        slug: "/"
      },
      textoAccessivel: "Navegar para pagina inicial"
    }
  }

  return (
    <Layout
      tags={getTags({ titulo: "404: Pagina nao encontrada", robots: "noindex, nofollow", site: contentfulSite })}
      heroType="ContentfulHeroTituloSimples"
    >
      {renderComponent(hero)}
      {renderComponent(bodyComp)}
    </Layout>
  )
}

export default NotFoundPage

export const notFoundQuery = graphql`
  query ($site: String!, $locale: String!) {
    contentfulSite(nome: { eq: $site }, node_locale: { eq: $locale }) {
      ...FragmentContentfulSite
    }

    site {
      ...FragmentSite
    }
  }
`
